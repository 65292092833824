import React, { FunctionComponent, useEffect, useMemo, useReducer, useRef } from 'react';
import { ContentWrapper, GACSWrapper, IncidentsPane, InfoHeader } from 'styled/components/dataPane';
import { ModuleType, SensorAvgFilter } from 'types';
import DataPaneMenu from '../DataPaneMenu';
import { DateRange, DateSpan, createFromToDate } from 'utils/timePeriod';
import { ReportDataPaneAction, ReportDataPaneState } from './types';
import { useTranslation } from 'react-i18next';
import { createReportGraphData } from './gacs.helpers';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { H3White } from 'styled/components/text';
import EfficiencyGraph from 'components/Graph/gacs/EfficiencyGraph';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import { Space } from 'views/authenticated/types';
import { DisplayOnPrint } from 'styled/components/ebs';
import { ReportHead } from 'styled/components/gacs';
import unicaIcon from '../../../assets/icons/by-unica.svg';
import { useUser } from 'context/UserContext';
import { useMultiSensorSumTotalData } from 'hooks/api/useMultiSensorSumTotalData';
import { DefaultButton } from 'styled/components/buttons';

interface GACSReportPaneProps {
    building: Space;
    groups: Space[];
    onSelectDateAction: (action: string, span: DateSpan, customDate?: DateRange) => void;
    action: string;
    timespan: DateSpan;
    customDate: DateRange;
}

const GACSReportPane: FunctionComponent<GACSReportPaneProps> = ({
    building,
    groups,
    onSelectDateAction,
    action,
    timespan,
    customDate,
}) => {
    const { t } = useTranslation();
    const componentRef = useRef<HTMLDivElement>(null);
    const { userState } = useUser();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { graphData, isBarChart } = state;
    const sensorParams = useMemo(
        () => getSensorParams(action, timespan, customDate, groups),
        [action, timespan, customDate, groups]
    );
    const { data: gacsData, isLoading } = useMultiSensorSumTotalData(sensorParams);

    useEffect(() => {
        if (gacsData) {
            dispatch({
                type: 'SET_GRAPH_DATA',
                data: gacsData,
                groups: groups,
                action: action,
                timespan: timespan,
                customDate: customDate,
            });
        }
    }, [gacsData]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `GASC report ${building.name} ${format(new Date(sensorParams.from), 'dd-MM-yyyy')} t/m ${format(
            new Date(sensorParams.to),
            'dd-MM-yyyy'
        )}`,
        removeAfterPrint: true,
    });

    return (
        <IncidentsPane>
            <DataPaneMenu
                customDate={customDate}
                title={building.name}
                selectedAction={action}
                onSelectAction={onSelectDateAction}
                module={ModuleType.ENERGY}
                allowCustomPeriod={true}
            />
            <GACSWrapper>
                {(isLoading || graphData === null) && <LoadingOverlay dark={true} isSmall={true} />}
                {!isLoading && graphData?.length && graphData.every(s => !s.data.length) && (
                    <H3White>No data for selected period</H3White>
                )}
                {!isLoading && graphData?.length && graphData?.some(s => s.data.length) && (
                    <DefaultButton onClick={handlePrint}>{t('energy.gacs.downloadButton')}</DefaultButton>
                )}
            </GACSWrapper>

            <div ref={componentRef} style={{ height: '100%' }}>
                <>
                    {graphData && (
                        <DisplayOnPrint>
                            <ReportHead>
                                <h3>
                                    {t('energy.gacs.pdf.title', {
                                        dateRange: `${format(new Date(sensorParams.from), 'dd MMM yyyy')} - ${format(
                                            new Date(sensorParams.to),
                                            'dd MMM yyyy'
                                        )}`,
                                    })}
                                </h3>
                                <img src={unicaIcon} alt="Building Insight by Unica" />
                            </ReportHead>
                            <p>{t('energy.gacs.pdf.organisation', { organisation: userState.Tenants[0].Name })}</p>
                            <p>{t('energy.gacs.pdf.building', { building: building.name })}</p>
                            <p>{t('energy.gacs.datapane.title')}</p>

                            {graphData.map(data => (
                                <div style={{ paddingTop: '10px', pageBreakInside: 'avoid' }} key={data.group.id}>
                                    <ContentWrapper>
                                        <InfoHeader>
                                            <H3White
                                                style={{ color: 'white', marginBottom: '10px' }}
                                            >{`${data.group.name}`}</H3White>
                                        </InfoHeader>
                                        <EfficiencyGraph graphData={data} isBarChart={isBarChart} />
                                    </ContentWrapper>
                                </div>
                            ))}
                            <h3>{t('energy.gacs.explain.title')}</h3>
                            <p>{t('energy.gacs.explain.text')}</p>
                        </DisplayOnPrint>
                    )}
                </>
            </div>
        </IncidentsPane>
    );
};

export default GACSReportPane;

export const initialState: ReportDataPaneState = {
    graphData: null,
    averageData: [],
    isBarChart: false,
};

const reducer = (state: ReportDataPaneState, action: ReportDataPaneAction): ReportDataPaneState => {
    switch (action.type) {
        case 'SET_GRAPH_DATA': {
            const graphData = createReportGraphData(
                action.data,
                action.action,
                action.timespan,
                action.customDate,
                action.groups
            );
            return { ...state, graphData };
        }
        case 'TOGGLE_CHART': {
            return {
                ...state,
                isBarChart: action.isBarChart,
            };
        }
    }
};

export const getSensorParams = (
    action: string,
    timespan: DateSpan,
    customDate: DateRange,
    groups?: Space[],
    ids?: string[]
) => {
    const { from, to } = action === DateSpan.CUSTOM ? customDate : createFromToDate(timespan);
    const sensorIds = groups ? groups.flatMap(g => g.sensors.map(s => s.id)) : ids;
    return {
        ids: sensorIds,
        from: from,
        to: to,
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
    };
};
