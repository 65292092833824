import { AirMetricsQualityAction, KpiUnit, Sensor, SensorSubType, SensorType } from 'types';

import { format } from 'date-fns';
import i18n from '../../i18n';
import styled, { css } from 'styled-components';
import { getLightColor, theme } from 'styled/Theme';
import SVG from 'react-inlinesvg';

import { getModule } from '../../components/DataPane/sensors/sensors.helpers';

import temperatureIcon from '../../assets/icons/i-s-temperature.svg';
import humidityIcon from '../../assets/icons/i-s-humidity.svg';
import waterIcon from '../../assets/icons/i-m-water.svg';
import luminocityIcon from '../../assets/icons/i-s-luminocity.svg';
import CO2Icon from '../../assets/icons/i-s-co-2.svg';
import motionIcon from '../../assets/icons/i-s-movement.svg';
import batteryIcon from '../../assets/icons/i-s-battery.svg';
import airIcon from '../../assets/icons/i-m-air.svg';
import gasIcon from '../../assets/icons/i-s-gas.svg';
import coolingIcon from '../../assets/icons/i-s-cooling.svg';
import energyIcon from '../../assets/icons/i-m-energy.svg';
import unknownIcon from '../../assets/icons/icon-sensor-unknown.svg';
import { SensorValue } from 'components/Graph/types';
import { isDaysTimespan } from 'utils/timePeriod';
import { calculateUsage } from 'components/DataPane/statistics/water/WaterStatsDataPane';

interface Selected {
    hasIssue?: boolean;
    selected?: boolean;
}

interface AirTD {
    colId: string;
    ccq: string;
    co2Quality: string;
    humidityQuality: string;
    temperatureQuality: string;
    requestedQuality: string;
}

interface SensorWrapper {
    collapsed: boolean;
    levels?: number;
}
export const SensorWrapper = styled.div<SensorWrapper>`
    display: grid;
    grid-template-columns: ${({ levels }) =>
        levels === 6 ? 'repeat(6, minmax(0, 1fr))' : 'repeat(5, minmax(0, 1fr))'};
    grid-gap: 30px;
    grid-auto-flow: column;
    height: 100%;
    min-height: ${({ collapsed }) => (collapsed ? 'calc(26vh)' : '80%')};
    margin: ${({ collapsed }) => (collapsed ? '20px 0px 10px 45px' : '20px 0px 20px 45px')};
`;

export const AirSensorWrapper = styled.div<SensorWrapper>`
    display: flex;
    min-height: ${({ collapsed }) => (collapsed ? 'calc(26vh)' : '80%')};
    margin: ${({ collapsed }) => (collapsed ? '20px 50px 10px 30px' : '20px 50px 20px 30px')};
`;

export const SensorListContainer = styled.div`
    line-height: 3em;
    overflow-y: auto;
    overflow-x: hidden;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const SensorList = styled.ul`
    border: none;
    padding: 0;
    margin: 0;
`;

export const Name = styled.span`
    float: left;
    line-height: 3em;
    padding: 0 15px 0 0;
`;

export const AirSensorListItem = styled.li`
    div {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        display: table;
    }
`;

export const AirSensorName = styled(Name)<Selected>`
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? theme.colors.text : 'none')};
    color: ${({ selected }) => (selected ? theme.colors.white : theme.colors.text)};
    border-radius: 4px;
    padding: 0 15px;
`;

export const AirSensorValue = styled.div<AirTD>`
    ${({ colId, ccq, co2Quality, humidityQuality, temperatureQuality, requestedQuality, theme }) => {
        if (colId === 'temperature') {
            return css`
                color: ${temperatureQuality === AirMetricsQualityAction.GOOD
                    ? theme.colors.air
                    : temperatureQuality === AirMetricsQualityAction.ACCEPTABLE
                    ? theme.colors.mEnergy
                    : temperatureQuality === AirMetricsQualityAction.POOR
                    ? theme.colors.negative
                    : theme.colors.text};
                font-weight: ${temperatureQuality === requestedQuality ? 'bold' : 'normal'};
            `;
        }
        if (colId === 'ccq') {
            return css`
                color: ${ccq.startsWith(i18n.t('sensors.good'))
                    ? theme.colors.air
                    : ccq.startsWith(i18n.t('sensors.acceptable'))
                    ? theme.colors.mEnergy
                    : ccq.startsWith(i18n.t('sensors.poor'))
                    ? theme.colors.negative
                    : theme.colors.text};
                font-weight: bold;
            `;
        }
        if (colId === 'humidity') {
            return css`
                color: ${humidityQuality === AirMetricsQualityAction.GOOD
                    ? theme.colors.air
                    : humidityQuality === AirMetricsQualityAction.ACCEPTABLE
                    ? theme.colors.mEnergy
                    : humidityQuality === AirMetricsQualityAction.POOR
                    ? theme.colors.negative
                    : theme.colors.text};
                font-weight: ${humidityQuality === requestedQuality ? 'bold' : 'normal'};
            `;
        }
        if (colId === 'CO2') {
            return css`
                color: ${co2Quality === AirMetricsQualityAction.GOOD
                    ? theme.colors.air
                    : co2Quality === AirMetricsQualityAction.ACCEPTABLE
                    ? theme.colors.mEnergy
                    : co2Quality === AirMetricsQualityAction.POOR
                    ? theme.colors.negative
                    : theme.colors.text};
                font-weight: ${co2Quality === requestedQuality ? 'bold' : 'normal'};
            `;
        }
    }};
`;

export const CounterButtons = styled.span`
    margin-right: 0px;
    float: right;
`;

export const SensorListItem = styled.li<Selected>`
    cursor: pointer;
    div {
        font-size: 14px;
        padding: 0 15px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        background-color: ${({ selected }) => (selected ? theme.colors.text : 'none')};
        color: ${({ selected }) => (selected ? theme.colors.white : theme.colors.text)};
        p {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: end;
        }

        p:nth-child(1) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            word-break: break-all;
        }
        p:nth-child(3) {
            margin-left: auto;
            min-width: 74px;
        }
        ${({ hasIssue }) => {
            return hasIssue
                ? css`
                      p:nth-child(2) {
                          &::after {
                              content: '•';
                              color: ${theme.colors.negative};
                              margin-inline: 8px;
                              line-height: 0px;
                              font-size: 32px;
                          }
                      }
                  `
                : '';
        }};
    }
`;

export const SensorContent = styled.div`
    display: flex;
`;

export const KpiWrapper = styled.div`
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px 30px;
    display: flex;
    background-color: #2a2e33;
    border-bottom: 1px solid #434951;
`;

export const SensorTypeWrapper = styled.div<ModuleDivInterface>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    ${({ $active, sensortype }) =>
        $active
            ? css`
                  background-color: ${getLightColor(getModule(sensortype))};
              `
            : css`
                  background: ${theme.colors.greyD};
              `};
`;

export const SensorValueWrapper = styled(SensorTypeWrapper)`
    width: auto;
    height: 40px;
    font-weight: bold;
    padding: 0px 15px;
    color: ${({ $active, theme, $hasError }) =>
        $hasError ? theme.colors.negative : $active ? theme.colors.text : theme.colors.white};
`;

export const SensorKpiWrapper = styled.div`
    gap: 1px;
    display: flex;
    overflow: hidden;
    cursor: pointer;
    height: 40px;
    border-radius: 4px;
`;

export const SensorTypeIcon = styled(SVG)<ModuleDivInterface>`
    width: 15px;
    height: 15px;
    line-height: 15px;
    & path {
        fill: ${({ $active, theme, $hasError }) => {
            return $hasError ? theme.colors.negative : $active ? theme.colors.text : theme.colors.white;
        }};
    }
    ${({ $active, sensortype }) =>
        $active
            ? css`
                  background-color: ${getLightColor(getModule(sensortype))};
              `
            : css`
                  background: ${theme.colors.greyD};
              `};
`;

interface ModuleDivInterface {
    $active?: boolean;
    $hasError?: boolean;
    sensortype: SensorType;
}

export const getSensorIcon = (sensorType: SensorType) => {
    switch (sensorType) {
        case SensorType.TEMPERATURE:
            return temperatureIcon;
        case SensorType.CO2:
            return CO2Icon;
        case SensorType.HUMIDITY:
            return humidityIcon;
        case SensorType.WATER:
            return waterIcon;
        case SensorType.MOTION:
            return motionIcon;
        case SensorType.BATTERY_VOLTAGE:
            return batteryIcon;
        case SensorType.ILLUMINANCE:
            return luminocityIcon;
        case SensorType.CCQ:
            return airIcon;
        case SensorType.ELECTRICITY:
        case SensorType.CURRENT:
        case SensorType.CURRENT_INDEX:
            return energyIcon;
        case SensorType.HEATING:
            return gasIcon;
        case SensorType.COOLING:
            return coolingIcon;
        case SensorType.GAS:
            return gasIcon;
        default:
            return unknownIcon;
    }
};

export const getUnit = (kpiUnit: KpiUnit, subType?: SensorSubType) => {
    switch (kpiUnit) {
        case KpiUnit.PPM:
            return ' ppm';
        case KpiUnit.CELSIUS:
        case KpiUnit.C:
            return '°C';
        case KpiUnit.COUNT:
            switch (subType) {
                case SensorSubType.EMPTY || null:
                    return ` ${i18n.t('infoHeader.occupancy.motions')}`;
                case SensorSubType.COUNT:
                    return ` ${i18n.t('infoHeader.occupancy.motionsCount')}`;
                default:
                    return ' unit';
            }

        case KpiUnit.RH:
        case KpiUnit.PERCENTAGE:
            return '%';
        case KpiUnit.M3:
            return ' L';
        case KpiUnit.M3H:
            return ' l/h';
        case KpiUnit.LX:
            return ' Lx';
        case KpiUnit.V:
            return ' V';
        case KpiUnit.SCORE:
            return '';
        case KpiUnit.MA:
            return ' mA';
        case KpiUnit.MAH:
            return ' mAh';
        case KpiUnit.KWH_UPPERCASE:
        case KpiUnit.KWH:
            return ' kWh';
        case KpiUnit.WH:
            return ' Wh';
        case KpiUnit.GJ:
            return ' Gj';
        default:
            return ' unit';
    }
};

export const getSensorValue = (sensor: Sensor, sensorData: SensorValue[], timespan: string) => {
    switch (sensor.type) {
        case SensorType.CCQ:
        case SensorType.TEMPERATURE:
        case SensorType.CO2:
        case SensorType.HUMIDITY:
        case SensorType.BATTERY_VOLTAGE:
        case SensorType.ILLUMINANCE:
        case SensorType.ELECTRICITY:
        case SensorType.HEATING:
        case SensorType.COOLING:
            return `${sensor.latestKpi.value}${getUnit(sensor.unit)}`;
        case SensorType.WATER:
            return `${calculateUsage(sensorData)} L`;
        case SensorType.MOTION:
            switch (sensor.subType) {
                case SensorSubType.EMPTY || null: {
                    const totalMotions = calculateMotions(sensorData, timespan);
                    return `${totalMotions}${
                        isDaysTimespan(timespan)
                            ? `/${i18n.t('common.time.hour').toLowerCase()}`
                            : `/${i18n.t('common.time.day').toLowerCase()}`
                    }`;
                }
                case SensorSubType.COUNT:
                    return `${sensor.latestKpi.value}`;
                default:
                    return `${sensor.latestKpi.value}${getUnit(sensor.unit)}`;
            }
        default:
            return `${sensor.latestKpi.value}${getUnit(sensor.unit)}`;
    }
};

const calculateMotions = (sensorData: SensorValue[], timespan: string) => {
    const timeFormat = isDaysTimespan(timespan) ? 'yyyy-MM-dd HH' : 'yyyy-MM-dd';
    const lastDateTime = format(new Date(sensorData[sensorData.length - 1].timestamp * 1000), timeFormat);

    let totalMotions = 0;
    for (let i = sensorData.length - 1; i >= 0; --i) {
        const currentDateTime = format(new Date(sensorData[i].timestamp * 1000), timeFormat);
        if (currentDateTime !== lastDateTime) {
            break;
        }
        totalMotions = totalMotions + sensorData[i].value;
    }

    return totalMotions;
};
