import React, { useEffect, useReducer, useState } from 'react';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import EnergyStatsDataPane from 'components/DataPane/statistics/energy/EnergyStatsDataPane';
import ModulesSubMenu, { navItemsEnergy } from 'components/Submenu/ModulesSubMenu';
import { SpaceSuggestion } from 'components/SearchAutoComplete';

import { useTenant } from 'context/TenantContext';

import { useSensorsByModulesData } from 'hooks/api/useSensorsByModulesData';
import { useMeterData } from 'hooks/api/useMeterData';

import { DateSpan, createFromToDate } from 'utils/timePeriod';

import { ModuleType, SensorSubType, SpaceSumEnergyConsumption } from 'types';
import { EnergyMeterAction, EnergyMeterState, RoomUsage } from './types';
import { EnergyConstants, Meter, MeterSumData, SensorSelection } from '../types';
import MeterSensorSelector from 'components/SensorSelector/MeterSensorSelector';
import { useMeterOverviewData } from 'hooks/api/useMeterOverviewSum';

const DEFAULT_ELECTRICITY_CONSTANTS: EnergyConstants = { co2Emission: 1, price: 1 };
const DEFAULT_GAS_CONSTANTS: EnergyConstants = { co2Emission: 1, price: 1 };
const emptyList = { meters: [] as Meter[], selectedMeter: '' };
const fakeBuildingUsage = [{ consumption: 0, production: 0, feedin: 0, id: '' }];

const EnergyMeterView: React.FunctionComponent = () => {
    const { getBuildingData, contracts } = useTenant();
    const [state, dispatch] = useReducer(EnergySubmeterReducer, initialState);
    const [isExpanded, setIsExpanded] = useState(false);
    const {
        buildingConstants,
        meterStructure,
        breadCrumbs,
        city,
        from,
        to,
        selectedMainElectricitySensors,
        selectedSubElectricitySensors,
        selectedEwattchSensors,
        meterOverview,
        suggestions,
        selectedMeter,
        usageByMeter,
    } = state;

    const { data: meterSumData } = useMeterOverviewData(from, to);
    const { data: meterData } = useMeterData(SensorSubType.CONSUMPTION);
    const { data, isLoading } = useSensorsByModulesData([ModuleType.ENERGY]);

    useEffect(() => {
        if (meterData && data) {
            const sensorsEnergy = data[0];

            dispatch({
                type: 'INIT_SENSORS',
                meterData: meterData.meters,
                sensorDataEnergy: sensorsEnergy,
                buildingData: getBuildingData(),
            });
        }
    }, [meterData, data]);

    useEffect(() => {
        if (meterSumData && meterStructure.length) {
            meterStructure.forEach(m => {
                recursiveAddMeterValue(m.subMeters, meterSumData);
            });

            dispatch({
                type: 'ADD_USAGE_DATA',
                meterData: meterStructure,
            });
        }
    }, [meterSumData, meterStructure]);

    return (
        <>
            <ModulesSubMenu
                subNavItems={navItemsEnergy}
                suggestions={suggestions}
                breadCrumbs={isExpanded ? breadCrumbs : []}
                onSearch={selectedMeter =>
                    dispatch({
                        type: 'SEARCH_SENSOR',
                        selectedMeter: selectedMeter,
                    })
                }
            />
            {isLoading && <LoadingOverlay />}

            {Boolean(meterOverview.length) && (
                <MeterSensorSelector
                    meters={meterOverview}
                    onEquipSelect={(selectAction, sensorId) => dispatch({ type: selectAction, meterId: sensorId })}
                    collapsed={Boolean(selectedMainElectricitySensors.length)}
                />
            )}
            {Boolean(selectedMainElectricitySensors.length) && (
                <EnergyStatsDataPane
                    selectedBuildingId=""
                    usageByBuilding={fakeBuildingUsage}
                    usageByRoom={usageByMeter}
                    isBuilding={false}
                    buildingName={breadCrumbs[breadCrumbs.length - 1]}
                    contracts={contracts}
                    buildingConstants={buildingConstants}
                    selectedRoom={selectedMeter}
                    city={city}
                    mainElectricitySensors={selectedMainElectricitySensors}
                    subElectrictySensors={selectedSubElectricitySensors}
                    ewattchSensors={selectedEwattchSensors}
                    gasSensors={[]}
                    productionSensors={[]}
                    feedinSensors={[]}
                    occupancySensors={[]}
                    onExpand={setIsExpanded}
                    onDateSelect={(action, timespan, customDate) =>
                        dispatch({ type: 'CHANGE_DATE_SPAN', action, timespan, customDate })
                    }
                />
            )}
        </>
    );
};

export default EnergyMeterView;

export const initialState: EnergyMeterState = {
    ewattchMeta: [],
    selectedEwattchSensors: [],
    buildingConstants: {
        gasConstants: DEFAULT_GAS_CONSTANTS,
        electricityConstants: DEFAULT_ELECTRICITY_CONSTANTS,
    },
    buildingStructure: [],
    city: '',
    sensors: [],
    meterOverview: [],
    meterStructure: [],
    selectedMeter: '',
    selectedMainElectricitySensors: [],
    selectedSubElectricitySensors: [],
    breadCrumbs: [],
    from: createFromToDate(DateSpan.TWO_DAYS).from,
    to: createFromToDate(DateSpan.TWO_DAYS).to,
    suggestions: [],
    usageByMeter: [],
};

const EnergySubmeterReducer = (state: EnergyMeterState, action: EnergyMeterAction): EnergyMeterState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const sensorSelection = localStorage.getItem('energy-meter-selection');
            const parsedSelection: SensorSelection | null = sensorSelection ? JSON.parse(sensorSelection) : null;
            const selectedMeterId = getselectedMeter(parsedSelection);
            const sensors = action.sensorDataEnergy;

            const meterData = action.meterData;
            const buildingStructure = action.buildingData;

            const suggestions: SpaceSuggestion[] = [];
            meterData.forEach(meter => {
                const location = [meter.name];
                const ids = [meter.pointId];
                const suggestion: SpaceSuggestion = {
                    name: meter.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);
                recursiveCreateSuggestions(meter.subMeters, suggestions, ids, location);
            });

            const selectedFirstLevelMeter = meterData.find(i => i.pointId === parsedSelection?.selectedId1);
            const selectedSecondLevelMeter = selectedFirstLevelMeter?.subMeters.find(
                i => i.pointId === parsedSelection?.selectedId2
            );
            const selectedThirdLevelMeter = selectedSecondLevelMeter?.subMeters.find(
                i => i.pointId === parsedSelection?.selectedId3
            );
            const selectedFourthLevelMeter = selectedThirdLevelMeter?.subMeters.find(
                i => i.pointId === parsedSelection?.selectedId4
            );
            const selectedFifthLevelMeter = selectedFourthLevelMeter?.subMeters.find(
                i => i.pointId === parsedSelection?.selectedId5
            );
            const selectedSixthLevelMeter = selectedFifthLevelMeter?.subMeters.find(
                i => i.pointId === parsedSelection?.selectedId6
            );
            const breadCrumbs = [
                selectedFirstLevelMeter?.name,
                selectedSecondLevelMeter?.name,
                selectedThirdLevelMeter?.name,
                selectedFourthLevelMeter?.name,
                selectedFifthLevelMeter?.name,
                selectedSixthLevelMeter?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const selectedSensor = sensors.find(s => s.id === selectedMeterId);

            const selectedBuilding = selectedFirstLevelMeter
                ? buildingStructure.find(
                      b =>
                          b.id ===
                          action.sensorDataEnergy.find(s => s.id === selectedFirstLevelMeter.pointId).buildingRef
                  )
                : '';

            return {
                ...state,
                sensors,
                city: selectedBuilding ? selectedBuilding.openWeather : '',
                buildingConstants: {
                    gasConstants: selectedBuilding ? selectedBuilding.gasConstants : DEFAULT_GAS_CONSTANTS,
                    electricityConstants: selectedBuilding
                        ? selectedBuilding.electricityConstants
                        : DEFAULT_ELECTRICITY_CONSTANTS,
                },
                buildingStructure: buildingStructure,
                meterStructure: meterData,
                meterOverview: [
                    {
                        meters: meterData || [],
                        selectedMeter: parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '',
                    },
                    {
                        meters: selectedFirstLevelMeter?.subMeters || [],
                        selectedMeter: parsedSelection?.selectedId2 ? parsedSelection.selectedId2 : '',
                    },
                    {
                        meters: selectedSecondLevelMeter?.subMeters || [],
                        selectedMeter: parsedSelection?.selectedId3 ? parsedSelection.selectedId3 : '',
                    },
                    {
                        meters: selectedThirdLevelMeter?.subMeters || [],
                        selectedMeter: parsedSelection?.selectedId4 ? parsedSelection.selectedId4 : '',
                    },
                    {
                        meters: selectedFourthLevelMeter?.subMeters || [],
                        selectedMeter: parsedSelection?.selectedId5 ? parsedSelection.selectedId5 : '',
                    },
                    {
                        meters: selectedFifthLevelMeter?.subMeters || [],
                        selectedMeter: parsedSelection?.selectedId6 ? parsedSelection.selectedId6 : '',
                    },
                ],
                selectedMainElectricitySensors: selectedSensor ? [selectedSensor] : [],
                selectedSubElectricitySensors: sensors.filter(s => s.subMeterRoomRef === selectedSensor?.roomRef),
                selectedMeter: selectedMeterId,
                breadCrumbs,
                suggestions,
            };
        }
        case 'SELECT_SPACE1': {
            localStorage.setItem(
                'energy-meter-selection',
                JSON.stringify({
                    selectedId1: action.meterId,
                })
            );
            const selectedFirstLevelMeter = state.meterOverview[0].meters.find(i => i.pointId === action.meterId);

            const selectedBuilding = selectedFirstLevelMeter
                ? state.buildingStructure.find(
                      b => b.id === state.sensors.find(s => s.id === selectedFirstLevelMeter.pointId).buildingRef
                  )
                : '';

            const breadCrumbs = [state.meterOverview[0].meters.find(i => i.pointId === action.meterId)?.name].filter(
                (i): i is string => {
                    return typeof i === 'string';
                }
            );

            return {
                ...state,
                city: selectedBuilding ? selectedBuilding.openWeather : '',
                buildingConstants: {
                    gasConstants: selectedBuilding ? selectedBuilding.gasConstants : DEFAULT_GAS_CONSTANTS,
                    electricityConstants: selectedBuilding
                        ? selectedBuilding.electricityConstants
                        : DEFAULT_ELECTRICITY_CONSTANTS,
                },
                meterOverview: [
                    {
                        meters: state.meterOverview[0].meters || [],
                        selectedMeter: action.meterId,
                    },
                    {
                        meters: selectedFirstLevelMeter?.subMeters || [],
                        selectedMeter: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedMainElectricitySensors: [],
                selectedSubElectricitySensors: [],
                selectedMeter: '',
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE2': {
            localStorage.setItem(
                'energy-meter-selection',
                JSON.stringify({
                    selectedId1: state.meterOverview[0].selectedMeter,
                    selectedId2: action.meterId,
                })
            );

            const breadCrumbs = [
                state.meterOverview[0].meters.find(i => i.pointId === state.meterOverview[0].selectedMeter)?.name,
                state.meterOverview[1].meters.find(i => i.pointId === action.meterId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const selectedSensor = state.sensors.find(s => s.id === action.meterId);

            return {
                ...state,
                meterOverview: [
                    state.meterOverview[0],
                    {
                        meters: state.meterOverview[1].meters,
                        selectedMeter: action.meterId,
                    },
                    {
                        meters: state.meterOverview[1].meters.find(i => i.pointId === action.meterId)?.subMeters || [],
                        selectedMeter: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedMainElectricitySensors: selectedSensor ? [selectedSensor] : [],
                selectedSubElectricitySensors: state.sensors.filter(s => s.subMeterRoomRef === selectedSensor.roomRef),
                selectedMeter: action.meterId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE3': {
            localStorage.setItem(
                'energy-meter-selection',
                JSON.stringify({
                    selectedId1: state.meterOverview[0].selectedMeter,
                    selectedId2: state.meterOverview[1].selectedMeter,
                    selectedId3: action.meterId,
                })
            );

            const breadCrumbs = [
                state.meterOverview[0].meters.find(i => i.pointId === state.meterOverview[0].selectedMeter)?.name,
                state.meterOverview[1].meters.find(i => i.pointId === state.meterOverview[1].selectedMeter)?.name,
                state.meterOverview[2].meters.find(i => i.pointId === action.meterId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const selectedSensor = state.sensors.find(s => s.id === action.meterId);

            return {
                ...state,
                meterOverview: [
                    state.meterOverview[0],
                    state.meterOverview[1],
                    {
                        meters: state.meterOverview[2].meters,
                        selectedMeter: action.meterId,
                    },
                    {
                        meters: state.meterOverview[2].meters.find(i => i.pointId === action.meterId)?.subMeters || [],
                        selectedMeter: '',
                    },
                    emptyList,
                    emptyList,
                ],
                selectedMainElectricitySensors: selectedSensor ? [selectedSensor] : [],
                selectedSubElectricitySensors: state.sensors.filter(s => s.subMeterRoomRef === selectedSensor.roomRef),
                selectedMeter: action.meterId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE4': {
            localStorage.setItem(
                'energy-meter-selection',
                JSON.stringify({
                    selectedId1: state.meterOverview[0].selectedMeter,
                    selectedId2: state.meterOverview[1].selectedMeter,
                    selectedId3: state.meterOverview[2].selectedMeter,
                    selectedId4: action.meterId,
                })
            );

            const breadCrumbs = [
                state.meterOverview[0].meters.find(i => i.pointId === state.meterOverview[0].selectedMeter)?.name,
                state.meterOverview[1].meters.find(i => i.pointId === state.meterOverview[1].selectedMeter)?.name,
                state.meterOverview[2].meters.find(i => i.pointId === state.meterOverview[2].selectedMeter)?.name,
                state.meterOverview[3].meters.find(i => i.pointId === action.meterId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const selectedSensor = state.sensors.find(s => s.id === action.meterId);

            return {
                ...state,
                meterOverview: [
                    state.meterOverview[0],
                    state.meterOverview[1],
                    state.meterOverview[2],
                    {
                        meters: state.meterOverview[3].meters,
                        selectedMeter: action.meterId,
                    },
                    {
                        meters: state.meterOverview[3].meters.find(i => i.pointId === action.meterId)?.subMeters || [],
                        selectedMeter: '',
                    },
                    emptyList,
                ],
                selectedMainElectricitySensors: selectedSensor ? [selectedSensor] : [],
                selectedSubElectricitySensors: state.sensors.filter(s => s.subMeterRoomRef === selectedSensor.roomRef),
                selectedMeter: action.meterId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE5': {
            localStorage.setItem(
                'energy-meter-selection',
                JSON.stringify({
                    selectedId1: state.meterOverview[0].selectedMeter,
                    selectedId2: state.meterOverview[1].selectedMeter,
                    selectedId3: state.meterOverview[2].selectedMeter,
                    selectedId4: state.meterOverview[3].selectedMeter,
                    selectedId5: action.meterId,
                })
            );

            const breadCrumbs = [
                state.meterOverview[0].meters.find(i => i.pointId === state.meterOverview[0].selectedMeter)?.name,
                state.meterOverview[1].meters.find(i => i.pointId === state.meterOverview[1].selectedMeter)?.name,
                state.meterOverview[2].meters.find(i => i.pointId === state.meterOverview[2].selectedMeter)?.name,
                state.meterOverview[3].meters.find(i => i.pointId === state.meterOverview[3].selectedMeter)?.name,
                state.meterOverview[4].meters.find(i => i.pointId === action.meterId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const selectedSensor = state.sensors.find(s => s.id === action.meterId);

            return {
                ...state,
                meterOverview: [
                    state.meterOverview[0],
                    state.meterOverview[1],
                    state.meterOverview[2],
                    state.meterOverview[3],
                    {
                        meters: state.meterOverview[4].meters,
                        selectedMeter: action.meterId,
                    },
                    {
                        meters: state.meterOverview[4].meters.find(i => i.pointId === action.meterId)?.subMeters || [],
                        selectedMeter: '',
                    },
                ],
                selectedMainElectricitySensors: selectedSensor ? [selectedSensor] : [],
                selectedSubElectricitySensors: state.sensors.filter(s => s.subMeterRoomRef === selectedSensor.roomRef),
                selectedMeter: action.meterId,
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE6': {
            localStorage.setItem(
                'energy-meter-selection',
                JSON.stringify({
                    selectedId1: state.meterOverview[0].selectedMeter,
                    selectedId2: state.meterOverview[1].selectedMeter,
                    selectedId3: state.meterOverview[2].selectedMeter,
                    selectedId4: state.meterOverview[3].selectedMeter,
                    selectedId5: state.meterOverview[4].selectedMeter,
                    selectedId6: action.meterId,
                })
            );

            const breadCrumbs = [
                state.meterOverview[0].meters.find(i => i.pointId === state.meterOverview[0].selectedMeter)?.name,
                state.meterOverview[1].meters.find(i => i.pointId === state.meterOverview[1].selectedMeter)?.name,
                state.meterOverview[2].meters.find(i => i.pointId === state.meterOverview[2].selectedMeter)?.name,
                state.meterOverview[3].meters.find(i => i.pointId === state.meterOverview[3].selectedMeter)?.name,
                state.meterOverview[4].meters.find(i => i.pointId === state.meterOverview[4].selectedMeter)?.name,
                state.meterOverview[5].meters.find(i => i.pointId === action.meterId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const selectedSensor = state.sensors.find(s => s.id === action.meterId);

            return {
                ...state,
                meterOverview: [
                    state.meterOverview[0],
                    state.meterOverview[1],
                    state.meterOverview[2],
                    state.meterOverview[3],
                    state.meterOverview[4],
                    {
                        meters: state.meterOverview[5].meters,
                        selectedMeter: action.meterId,
                    },
                ],
                selectedMainElectricitySensors: selectedSensor ? [selectedSensor] : [],
                selectedSubElectricitySensors: state.sensors.filter(s => s.subMeterRoomRef === selectedSensor.roomRef),
                selectedMeter: action.meterId,
                breadCrumbs,
            };
        }
        case 'ADD_USAGE_DATA': {
            const usageByMeter: RoomUsage[] = [];

            action.meterData.forEach(b => {
                usageByMeter.push({ value: b.value, id: b.equipId });

                recursiveAddRoomUsage(b.subMeters, usageByMeter);
            });
            return {
                ...state,
                usageByMeter,
                meterStructure: action.meterData,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const { from, to } =
                action.action === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.timespan);

            return {
                ...state,
                usageByMeter: [],
                from,
                to,
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedMeter.ids);
            const selectedMeterId = getselectedMeter(sensorSelection);
            localStorage.setItem('energy-meter-selection', JSON.stringify(sensorSelection));

            const selectedFirstLevelMeter = state.meterStructure.find(i => i.pointId === sensorSelection?.selectedId1);
            const selectedSecondLevelMeter = selectedFirstLevelMeter?.subMeters.find(
                i => i.pointId === sensorSelection?.selectedId2
            );
            const selectedThirdLevelMeter = selectedSecondLevelMeter?.subMeters.find(
                i => i.pointId === sensorSelection?.selectedId3
            );
            const selectedFourthLevelMeter = selectedThirdLevelMeter?.subMeters.find(
                i => i.pointId === sensorSelection?.selectedId4
            );
            const selectedFifthLevelMeter = selectedFourthLevelMeter?.subMeters.find(
                i => i.pointId === sensorSelection?.selectedId5
            );
            const selectedSixthLevelMeter = selectedFifthLevelMeter?.subMeters.find(
                i => i.pointId === sensorSelection?.selectedId6
            );
            const breadCrumbs = [
                selectedFirstLevelMeter?.name,
                selectedSecondLevelMeter?.name,
                selectedThirdLevelMeter?.name,
                selectedFourthLevelMeter?.name,
                selectedFifthLevelMeter?.name,
                selectedSixthLevelMeter?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            const selectedBuilding = selectedFirstLevelMeter
                ? state.buildingStructure.find(
                      b => b.id === state.sensors.find(s => s.id === selectedFirstLevelMeter.pointId).buildingRef
                  )
                : '';

            const selectedSensor = state.sensors.find(s => s.id === selectedMeterId);

            return {
                ...state,
                city: selectedBuilding ? selectedBuilding.openWeather : '',
                buildingConstants: {
                    gasConstants: selectedBuilding ? selectedBuilding.gasConstants : DEFAULT_GAS_CONSTANTS,
                    electricityConstants: selectedBuilding
                        ? selectedBuilding.electricityConstants
                        : DEFAULT_ELECTRICITY_CONSTANTS,
                },
                meterOverview: [
                    {
                        meters: state.meterOverview[0].meters,
                        selectedMeter: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                    },
                    {
                        meters: selectedFirstLevelMeter?.subMeters || [],
                        selectedMeter: sensorSelection?.selectedId2 ? sensorSelection.selectedId2 : '',
                    },
                    {
                        meters: selectedSecondLevelMeter?.subMeters || [],
                        selectedMeter: sensorSelection?.selectedId3 ? sensorSelection.selectedId3 : '',
                    },
                    {
                        meters: selectedThirdLevelMeter?.subMeters || [],
                        selectedMeter: sensorSelection?.selectedId4 ? sensorSelection.selectedId4 : '',
                    },
                    {
                        meters: selectedFourthLevelMeter?.subMeters || [],
                        selectedMeter: sensorSelection?.selectedId5 ? sensorSelection.selectedId5 : '',
                    },
                    {
                        meters: selectedFifthLevelMeter?.subMeters || [],
                        selectedMeter: sensorSelection?.selectedId6 ? sensorSelection.selectedId6 : '',
                    },
                ],
                selectedMainElectricitySensors: selectedSensor ? [selectedSensor] : [],
                selectedSubElectricitySensors: state.sensors.filter(s => s.subMeterRoomRef === selectedSensor?.roomRef),
                breadCrumbs,
            };
        }
    }
};

export const createSensorSelection = (ids: string[]) => {
    const sensorSelection: SensorSelection = {
        selectedId1: '',
        selectedId2: '',
        selectedId3: '',
        selectedId4: '',
        selectedId5: '',
        selectedId6: '',
    };
    if (!ids) {
        return sensorSelection;
    }
    if (ids[0]) {
        sensorSelection['selectedId1'] = ids[0];
    }
    if (ids[1]) {
        sensorSelection['selectedId2'] = ids[1];
    }
    if (ids[2]) {
        sensorSelection['selectedId3'] = ids[2];
    }
    if (ids[3]) {
        sensorSelection['selectedId4'] = ids[3];
    }
    if (ids[4]) {
        sensorSelection['selectedId5'] = ids[4];
    }
    if (ids[5]) {
        sensorSelection['selectedId6'] = ids[5];
    }
    return sensorSelection;
};

export const getselectedMeter = (meters: SensorSelection | null) => {
    if (!meters) {
        return '';
    }
    if (meters.selectedId6) {
        return meters.selectedId6;
    }
    if (meters.selectedId5) {
        return meters.selectedId5;
    }
    if (meters.selectedId4) {
        return meters.selectedId4;
    }
    if (meters.selectedId3) {
        return meters.selectedId3;
    }
    if (meters.selectedId2) {
        return meters.selectedId2;
    }
    return '';
};

const recursiveCreateSuggestions = (
    meters: Meter[],
    suggestions: SpaceSuggestion[],
    ids: string[],
    locations: string[]
) => {
    if (meters.length === 0) {
        return;
    }
    meters.forEach(r => {
        const i = [...ids];
        i.push(r.pointId);

        const l = [...locations];
        l.push(r.name);

        const suggestion: SpaceSuggestion = {
            name: r.name,
            ids: i,
            location: l,
        };
        suggestions.push(suggestion);

        recursiveCreateSuggestions(r.subMeters, suggestions, i, l);
    });
};

const recursiveSumEnergyConsumption = (spaces: Meter[], spaceSumEnergyConsumption: SpaceSumEnergyConsumption[]) => {
    if (spaces.length === 0 || !spaceSumEnergyConsumption) {
        return;
    }

    for (const index in spaces) {
        const res = spaceSumEnergyConsumption.find(e => e.roomId === spaces[index].equipId);
        spaces[index].value = +res?.value.toFixed(0) || +spaces[index].value?.toFixed(0);
        recursiveSumEnergyConsumption(spaces[index].subMeters, spaceSumEnergyConsumption);
    }

    spaces.sort((a, b) => b.value - a.value);
};

const recursiveAddRoomUsage = (meters: Meter[], usageByRoom: RoomUsage[]) => {
    if (meters.length === 0) {
        return;
    }

    meters.forEach(r => {
        usageByRoom.push({ value: r.value, id: r.pointId });

        recursiveAddRoomUsage(r.subMeters, usageByRoom);
    });
};

const recursiveAddMeterValue = (meters: Meter[], usageByRoom: MeterSumData[]) => {
    if (meters.length === 0 || !usageByRoom) return;

    meters.forEach(m => {
        m.value = usageByRoom.find(u => u.key === m.pointId).value;
        recursiveAddMeterValue(m.subMeters, usageByRoom);
    });

    meters.sort((a, b) => b.value - a.value);
};
