import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { MeterSumData } from 'views/authenticated/types';

export const useMeterOverviewData = (startDate: string, endDate: string) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(['meterOverviewSum', startDate, endDate], async ({ signal }) => {
        const { data } = await axiosClient.get<MeterSumData[]>(config.sensorsMeterOverviewSum(startDate, endDate), {
            signal,
        });
        return data;
    });
};
